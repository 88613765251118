












import Vue, { PropType } from 'vue';
import { minValue, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';

export default Vue.extend({
  components: { ValidatedInput },
  validations() {
    return {
      order: {
        guestCount: {
          required,
          minValue: minValue(2),
        },
      },
    };
  },
  mixins: [validationMixin],
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
    },
  },
  watch: {
    isDirty: {
      immediate: true,
      handler() {
        this.setDirty();
      },
    },
    anyError: {
      immediate: true,
      handler() {
        this.setAnyError();
      },
    },
  },
  computed: {
    isDirty(): boolean {
      return this.$v.$anyDirty;
    },
    anyError(): boolean {
      return this.$v.$anyError;
    },
  },
  methods: {
    setDirty() {
      if (this.$v.$anyDirty) {
        this.order.isDirty = true;
      }
    },
    setAnyError() {
      this.order.anyError = this.$v.$anyError;
    },
  },
});
