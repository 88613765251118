








import Vue, { PropType, VueConstructor } from 'vue';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';
import OrderDto from '@/models/OrderDto';
import OwnerOrderCalendarService from '@/services/owner/OwnerOrderCalendarService';
import HostCalendar from '@/components/shared/HostCalendar.vue';
import FormaterMixin from '@/mixins/FormaterMixin.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  mixins: [FormaterMixin],
  components: { HostCalendar },
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
    },
  },
  data() {
    return {
      orders: [] as OrderDto[],
      sorterValue: { column: 'startTimeInUTC', asc: true },
      tableFilterValue: '',
      showDate: new Date(),
      loading: true,
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    calendarStartDate(): Date {
      return new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() - 1, 1);
    },

    calendarEndDate(): Date {
      return new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 2, 1);
    },

    selectedDate(): Date {
      return this.parseIsoDateTimeTz(this.order.start) ?? new Date();
    },
  },
  methods: {
    loadData() {
      this.loading = true;
      OwnerOrderCalendarService.getIndex(
        this.calendarStartDate,
        this.calendarEndDate,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue,
        [this.order.playroomId]
      ).then((res) => {
        this.orders = res.value.events;
        this.loading = false;
      });
    },
  },
});
