














import Vue, { PropType } from 'vue';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';
import BookingTypePart from '@/components/orderDetailEditParts/BookingTypePart.vue';
import HourlyBookingPart from '@/components/orderDetailEditParts/HourlyBookingPart.vue';
import OvernightBookingPart from '@/components/orderDetailEditParts/OvernightBookingPart.vue';
import GuestPart from '@/components/orderDetailEditParts/GuestPart.vue';
import { validationMixin } from 'vuelidate';

const validations = {
  order: {
    bookingType: {},
  },
};

export default Vue.extend({
  components: { BookingTypePart, HourlyBookingPart, OvernightBookingPart, GuestPart },
  mixins: [validationMixin],
  validations,
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
      required: true,
    },
  },
});
