













































import Vue, { PropType } from 'vue';
import { validationMixin } from 'vuelidate';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';

const validations = {
  order: {
    orderedPlayroomAdditions: {
      $each: {
        id: {},
        playroomAdditionId: {},
        additionName: {},
        additionalRent: {},
        count: {},
        hasChecked: {},
        bookingType: {},
        priceForHourly: {},
        priceForOvernight: {},
        isPricePerPiece: {},
        isPricePerTime: {},
      },
    },
  },
};

export default Vue.extend({
  mixins: [validationMixin],
  components: {
    ValidatedCheckbox,
  },
  validations,
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
      required: true,
    },
  },
  watch: {
    isDirty: {
      immediate: true,
      handler() {
        this.setDirty();
      },
    },
    anyError: {
      immediate: true,
      handler() {
        this.setAnyError();
      },
    },
  },
  computed: {
    isDirty(): boolean {
      return this.$v.$anyDirty;
    },
    anyError(): boolean {
      return this.$v.$anyError;
    },
    isHourlyBooking(): boolean {
      return this.order.bookingType == 'hourly';
    },
  },
  methods: {
    showOption(bookingType: string) {
      return bookingType == 'All' || bookingType.toLowerCase() == this.order.bookingType.toLowerCase();
    },
    setDirty() {
      if (this.$v.$anyDirty) {
        this.order.isDirty = true;
      }
    },
    setAnyError() {
      this.order.anyError = this.$v.$anyError;
    },
    translationKey(
      addition: { isPricePerPiece: { $model: boolean }; isPricePerTime: { $model: boolean } },
      bookingType: string
    ): string {
      let unitType = 'Price';
      if (addition.isPricePerPiece.$model) {
        unitType += 'PerPiece';
      }
      if (addition.isPricePerTime.$model) {
        unitType += 'PerTime' + bookingType;
      }
      return unitType;
    },

    formatCurrency(item: number) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item);
    },

    minus(additionOption: { hasChecked: { $model: boolean }; count: { $model: number } }) {
      if (additionOption.count.$model > 0) {
        additionOption.count.$model -= 1;
      }
      if (additionOption.count.$model == 0) {
        additionOption.hasChecked.$model = false;
      }
    },

    plus(additionOption: { hasChecked: { $model: boolean }; count: { $model: number } }) {
      additionOption.count.$model += 1;
      if (additionOption.count.$model > 0) {
        additionOption.hasChecked.$model = true;
      }
    },

    onHasCheckedChange(additionOption: { hasChecked: { $model: boolean }; count: { $model: number } }) {
      if (additionOption.hasChecked.$model) {
        if (additionOption.count.$model == 0) {
          additionOption.count.$model = 1;
        }
      } else {
        additionOption.count.$model = 0;
      }
    },
  },
});
