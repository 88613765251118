

























































import Vue, { PropType } from 'vue';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';
import PlayroomBookingEditInfoPart from '@/components/orderDetailEditParts/PlayroomBookingEditInfoPart.vue';
import EditBasicSettingPart from '@/components/orderDetailEditParts/EditBasicSettingPart.vue';
import DiscountExtraChargePart from '@/components/orderDetailEditParts/DiscountExtraCharge.vue';
import ServicesOptions from '@/components/orderDetailEditParts/ServicesOptions.vue';
import DeleteConfirmationModal from '@/components/messages/DeleteConfirmationModal.vue';
import OrderEditConfirmationModal from '@/components/orderDetailEditParts/OrderEditConfirmationModal.vue';
import OwnerOrderService from '@/services/owner/OwnerOrderService';
import ToastMessage from '@/models/ToastMessage';
import Notification from '@/components/shared/Notification.vue';
import { isBefore, parse } from 'date-fns';

export default Vue.extend({
  components: {
    PlayroomBookingEditInfoPart,
    EditBasicSettingPart,
    DiscountExtraChargePart,
    ServicesOptions,
    DeleteConfirmationModal,
    OrderEditConfirmationModal,
    Notification,
  },
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
    },
  },
  data() {
    return {
      internalOrder: {} as CustomerOrderDetailDto,
      showModal: false,
      isDirty: false,
      showConfirmationModal: false,
      reload: true,
      showNotification: false,
      ready: false,
      saving: false,
    };
  },
  watch: {
    anyError: {
      immediate: true,
      handler() {
        this.internalOrder.anyError = this.anyError;
      },
    },
  },
  computed: {
    anyError(): boolean {
      return this.internalOrder.anyError;
    },
    canSaved(): boolean {
      return !this.internalOrder.isDirty || this.anyError;
    },
    bookingIsInPast(): boolean {
      const start = parse(this.internalOrder.start, 'dd.MM.yyyy HH:mm', new Date());
      return isBefore(start, new Date());
    },
    bookingIsCancelled(): boolean {
      return this.order.status == 'Cancelled';
    },
    bookingIsRejected(): boolean {
      return this.order.status == 'Rejected';
    },
  },
  mounted() {
    this.order.isDirty = false;
    this.order.anyError = false;
    this.internalOrder = { ...this.order } as CustomerOrderDetailDto;
    this.internalOrder.orderedDiscountExtraCharge = [...this.order.orderedDiscountExtraCharge];
    this.internalOrder.orderedPlayroomAdditions = [...this.order.orderedPlayroomAdditions];
    this.ready = true;
  },
  methods: {
    onClickSaveChange() {
      this.showConfirmationModal = true;
    },
    onClickDiscard() {
      this.showModal = true;
    },
    closeDiscardModal() {
      this.showModal = false;
    },
    internalOnDiscard() {
      this.reload = false;
      this.internalOrder = { ...this.order } as CustomerOrderDetailDto;
      this.internalOrder.orderedDiscountExtraCharge = [...this.order.orderedDiscountExtraCharge];
      this.internalOrder.orderedPlayroomAdditions = [...this.order.orderedPlayroomAdditions];
      this.$nextTick(() => {
        this.reload = true;
      });

      this.showModal = false;
    },
    onUpdateShow(show: boolean, message: string) {
      let orderEditInfo = {
        countOfGuests: this.internalOrder.guestCount.toString(),
        orderId: this.internalOrder.orderId,
        bookingType: this.internalOrder.bookingType,
        orderedPlayroomAdditions: this.internalOrder.orderedPlayroomAdditions,
        orderedDiscountExtraCharge: this.internalOrder.orderedDiscountExtraCharge,
        checkIn: this.internalOrder.start,
        checkOut: this.internalOrder.end,
        messageToGuest: message,
      };
      this.saving = true;
      OwnerOrderService.editDetail(orderEditInfo).then(
        () => {
          this.saving = false;
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('components.hostOrderDetailButtons.successMsg').toString(), 'bg-success')
          );
          this.reload = false;
          this.internalOrder.isDirty = false;
          this.internalOrder.anyError = false;
          this.$nextTick(() => {
            this.reload = true;
          });
          this.showConfirmationModal = show;
          this.showNotification = true;
        },
        () => {
          this.saving = false;
        }
      );
    },
    onCloseModal() {
      this.showConfirmationModal = false;
    },
    handleCloseNotification() {
      this.showNotification = false;
      this.$emit('reloadData');
    },
  },
});
