
















import Vue from 'vue';
import { maxLength, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ValidatedTextArea from '@/components/forms/ValidatedTextArea.vue';
const validations = {
  message: {
    required,
    maxLength: maxLength(5000),
  },
};

export default Vue.extend({
  components: { ValidatedTextArea },
  validations,
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    btnLabel: {
      type: String,
      required: true,
    },
    note: {
      type: String,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      ready: false,
      message: '',
    };
  },
  computed: {},
  methods: {
    onUpdateShow() {
      this.$emit('onUpdateShow', false, this.message);
    },
    onClose() {
      this.$emit('onClose');
    },
  },
});
