
























import Vue, { PropType, VueConstructor } from 'vue';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import { validationMixin } from 'vuelidate';
import ValidatedTextArea from '@/components/forms/ValidatedTextArea.vue';
import SaveButton from '@/components/forms/SaveButton.vue';
import OwnerOrderService from '@/services/owner/OwnerOrderService';
import { maxLength } from 'vuelidate/lib/validators';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  mixins: [validationMixin, FormaterMixin],
  components: { ValidatedTextArea, SaveButton },
  validations: {
    order: {
      orderNotes: { maxLength: maxLength(1000) },
      guestNotes: { maxLength: maxLength(1000) },
    },
  },
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
    },
  },
  methods: {
    onSave() {
      return OwnerOrderService.editNote(this.order.orderId, this.order.orderNotes, this.order.guestNotes);
    },
  },
  computed: {},
});
