




































































import Vue, { PropType } from 'vue';
import CustomerOrderDetailDto, { DiscountExtraCharge } from '@/models/CustomerOrderDetailDto';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedTextArea from '@/components/forms/ValidatedTextArea.vue';
import { validationMixin } from 'vuelidate';
import { maxLength, required, minValue, helpers } from 'vuelidate/lib/validators';
import DeleteConfirmationModal from '@/components/messages/DeleteConfirmationModal.vue';

export default Vue.extend({
  components: { ValidatedSelect, ValidatedInput, ValidatedTextArea, DeleteConfirmationModal },
  mixins: [validationMixin],
  validations() {
    return {
      order: {
        orderedDiscountExtraCharge: {
          $each: {
            id: {},
            optionName: {
              required,
              maxLength: maxLength(20),
            },
            option: {
              required,
            },
            amount: {
              required,
              minValue: minValue(1),
              maxDiscount: helpers.withParams(
                { type: 'maxDiscount' },
                (val: string, discountExtraCharge: DiscountExtraCharge): boolean => {
                  const discountableLineItems = this.order.lineItems.filter((li) => li.type != 'Discount');
                  const orderAmount = discountableLineItems.reduce((a, b) => a + b.price * b.amount, 0);
                  let max = 10000;
                  if (discountExtraCharge.option == 'Discount') {
                    max = orderAmount;
                  }
                  const totalDiscount = this.order.orderedDiscountExtraCharge
                    .filter((d) => d.option == 'Discount')
                    .reduce((a, b) => a + Number(b.amount), 0);
                  return totalDiscount <= max;
                }
              ),
            },
            comment: {
              maxLength: maxLength(150),
            },
          },
        },
      },
    };
  },
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
      required: true,
    },
  },
  data() {
    return {
      options: ['Discount', 'ExtraCharge'],
      showDeleteModal: false,
      rowIndex: -1,
    };
  },
  watch: {
    isDirty: {
      immediate: true,
      handler() {
        this.setDirty();
      },
    },
    anyError: {
      immediate: true,
      handler() {
        this.setAnyError();
      },
    },
  },
  computed: {
    getOptions(): { value: string; label: string }[] {
      return this.options.map((t) => ({
        value: t,
        label: this.$t('enums.discountExtraCharge.' + t).toString(),
      }));
    },
    isDirty(): boolean {
      return this.$v.$anyDirty;
    },
    anyError(): boolean {
      return this.$v.$anyError;
    },
  },
  methods: {
    addDiscountExtraCharge() {
      this.$v.$touch();
      const discount = new DiscountExtraCharge();
      discount.optionName = this.$t('enums.discountExtraCharge.Discount').toString();
      this.order.orderedDiscountExtraCharge.push(discount);
    },

    discountTypeChange(discountExtraCharge: any) {
      const defaultDiscountName = this.$t('enums.discountExtraCharge.Discount').toString();
      const defaultExtraChargeName = this.$t('enums.discountExtraCharge.ExtraCharge').toString();
      if (
        discountExtraCharge.option.$model == 'Discount' &&
        discountExtraCharge.optionName.$model == defaultExtraChargeName
      ) {
        discountExtraCharge.optionName.$model = defaultDiscountName;
      }
      if (
        discountExtraCharge.option.$model == 'ExtraCharge' &&
        discountExtraCharge.optionName.$model == defaultDiscountName
      ) {
        discountExtraCharge.optionName.$model = defaultExtraChargeName;
      }
    },

    internalOnDelete() {
      let discounts = this.$v.order?.orderedDiscountExtraCharge?.$model as DiscountExtraCharge[];
      discounts.splice(this.rowIndex, 1);
      this.$v.$touch();
      this.closeDeleteModal();
    },

    openDeleteModal(index: number) {
      this.rowIndex = index;
      this.showDeleteModal = true;
    },

    closeDeleteModal() {
      this.rowIndex = -1;
      this.showDeleteModal = false;
    },

    setDirty() {
      if (this.$v.$anyDirty) {
        this.order.isDirty = true;
      }
    },
    setAnyError() {
      this.order.anyError = this.$v.$anyError;
    },
  },
});
