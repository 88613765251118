







import Vue, { PropType } from 'vue';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';
export default Vue.extend({
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
      required: true,
    },
  },
});
