


















































import Vue, { PropType } from 'vue';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';
import { de } from 'date-fns/locale';
import toDate from 'date-fns-tz/toDate';
import format from 'date-fns-tz/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import { validationMixin } from 'vuelidate';
import { parse } from 'date-fns';

const validations = {
  checkInTime: {},
  checkOutTime: {},
};

export default Vue.extend({
  components: { ValidatedSelect },
  mixins: [validationMixin],
  validations,
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
    },
  },
  data() {
    return {
      selectedDate: null as Date | null,
      checkInTime: '',
      checkOutTime: '',
      checkOutTimes: [] as { value: string; label: string }[],
      dataInit: true,
    };
  },
  watch: {
    order: {
      immediate: true,
      handler() {
        this.dataInit = true;
        this.selectedDate = parse(this.order.start.split('T')[0], 'yyyy-MM-dd', new Date());
        this.checkInTime = this.formatTime(this.order.start);
        this.checkOutTime = this.formatTime(this.order.end);
      },
    },
    selectedDate: {
      immediate: true,
      handler() {
        if (!this.dataInit) {
          this.checkInTime = this.checkInTimes[0].value;
          this.order.isDirty = true;
          this.setCheckInInOrder();
        }
      },
    },
    checkInTime: {
      immediate: true,
      handler() {
        this.getCheckOutTimes();
        this.setCheckInInOrder();
      },
    },
    checkOutTime: {
      immediate: true,
      handler() {
        this.setCheckInOutOrder();
      },
    },
    isDirty: {
      immediate: true,
      handler() {
        this.setDirty();
      },
    },
    anyError: {
      immediate: true,
      handler() {
        this.setAnyError();
      },
    },
  },
  computed: {
    isDirty(): boolean {
      return this.$v.$anyDirty;
    },
    anyError(): boolean {
      return this.$v.$anyError;
    },
    checkInDate(): string {
      return this.selectedDate && this.selectedDate != null
        ? this.selectedDate.getFullYear() +
            '-' +
            ('0' + (this.selectedDate.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + this.selectedDate.getDate()).slice(-2)
        : '';
    },

    checkInTimes(): { value: string; label: string }[] {
      let slots: { value: string; label: string }[] = [];
      for (let index = 0; index < 24; index++) {
        slots.push({ value: `${('0' + index).slice(-2)}:00`, label: `${('0' + index).slice(-2)}:00` });
        slots.push({ value: `${('0' + index).slice(-2)}:30`, label: `${('0' + index).slice(-2)}:30` });
      }
      return slots;
    },
  },

  methods: {
    setDirty() {
      if (this.$v.$anyDirty) {
        this.order.isDirty = true;
      }
    },
    setAnyError() {
      this.order.anyError = this.$v.$anyError;
    },
    getCheckOutTimes() {
      if (this.checkInTime) {
        this.checkOutTimes = [];
        let hour = Number(this.checkInTime.split(':')[0]);
        let min = this.checkInTime.split(':')[1];
        if (min == '00') {
          this.checkOutTimes.push({ value: `${('0' + hour).slice(-2)}:30`, label: `${('0' + hour).slice(-2)}:30` });
        }
        hour += 1;
        for (let index = hour; index < 24; index++) {
          this.checkOutTimes.push({ value: `${('0' + index).slice(-2)}:00`, label: `${('0' + index).slice(-2)}:00` });
          this.checkOutTimes.push({ value: `${('0' + index).slice(-2)}:30`, label: `${('0' + index).slice(-2)}:30` });
        }
        if (!this.dataInit) {
          this.checkOutTime = this.checkOutTimes[0].value;
        }
        this.dataInit = false;
      }
    },
    setCheckInInOrder() {
      if (this.selectedDate && this.checkInTime) {
        let dateString = this.formatDate(this.selectedDate) + ' ' + this.checkInTime;
        let date = toDate(dateString, { timeZone: 'Europe/Berlin' });
        date = utcToZonedTime(date, 'Europe/Berlin');
        this.order.start = format(date, 'Pp', { locale: de, timeZone: 'Europe/Berlin' });
      }
    },
    setCheckInOutOrder() {
      if (this.selectedDate && this.checkInTime) {
        let dateString = this.formatDate(this.selectedDate) + ' ' + this.checkOutTime;
        let date = toDate(dateString, { timeZone: 'Europe/Berlin' });
        date = utcToZonedTime(date, 'Europe/Berlin');
        this.order.end = format(date, 'Pp', { locale: de, timeZone: 'Europe/Berlin' });
      }
    },
    formatTime(dateString: string): string {
      let date = toDate(dateString, { timeZone: 'Europe/Berlin' });
      date = utcToZonedTime(date, 'Europe/Berlin');
      return format(date, 'HH:mm', { locale: de, timeZone: 'Europe/Berlin' });
    },
    formatDate(dt: Date): string {
      return format(dt, 'yyyy-MM-dd', { locale: de, timeZone: 'Europe/Berlin' });
    },
    formatDateZonedTime(dateString: string): string {
      let date = toDate(dateString, { timeZone: 'Europe/Berlin' });
      date = utcToZonedTime(date, 'Europe/Berlin');
      return format(date, 'Pp', { locale: de, timeZone: 'Europe/Berlin' });
    },
  },
});
