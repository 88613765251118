





























import ToastMessage from '@/models/ToastMessage';
import Vue from 'vue';
export default Vue.extend({
  props: {
    model: {
      type: Object,
      required: true,
    },
    onSave: {
      type: Function,
      required: true,
    },
    additionalErrorMessage: {
      type: String,
      required: false,
    },
    saving: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isDirty: false,
      isValid: true,
      tooltipText: '',
      showInvalidModal: false,
    };
  },
  watch: {
    model: {
      deep: true,
      immediate: true,
      handler() {
        this.isDirty = this.model.$anyDirty;
        this.isValid = !this.model.$anyError;
        this.tooltipText = this.getTooltipText();
      },
    },
  },
  computed: {
    canBeSaved(): boolean {
      return this.isDirty && this.isValid && !this.additionalErrorMessage && !this.saving;
    },
  },
  methods: {
    getTooltipText(): string {
      if (!this.isDirty) {
        return this.$t('components.SaveButton.no_change').toString();
      }
      if (!this.isValid) {
        return this.$t('components.SaveButton.not_valid').toString();
      }
      if (this.additionalErrorMessage) {
        return this.additionalErrorMessage;
      }
      return '';
    },

    internalSave() {
      this.model.$touch();
      if (this.model.$invalid) {
        this.showInvalidModal = true;
      } else {
        this.onSave().then(
          () => {
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(this.$t('forms.common.save_successful').toString(), 'bg-success')
            );
            this.$emit('setErrorMessage', '');
            this.model.$reset();
          },
          (error: string) => {
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(this.$t('forms.common.save_error').toString(), 'bg-warning')
            );
            this.$emit('setErrorMessage', error);
          }
        );
      }
    },
  },
});
