














































































import Vue, { PropType } from 'vue';
import BookingButtonsModal from '@/components/orderDetailParts/BookingButtonsModal.vue';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';
import OwnerOrderService from '@/services/owner/OwnerOrderService';
import ToastMessage from '@/models/ToastMessage';
import { isBefore, parseISO } from 'date-fns';
import Notification from '@/components/shared/Notification.vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedTextArea from '@/components/forms/ValidatedTextArea.vue';
import { validationMixin } from 'vuelidate';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';

export default Vue.extend({
  components: {
    BookingButtonsModal,
    Notification,
    ValidatedInput,
    ValidatedTextArea,
  },
  mixins: [validationMixin],
  validations: {
    cancellationFeeInPercentage: { required, minValue: minValue(0), maxValue: maxValue(100) },
    cancelationMessage: { required },
  },
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
    },
  },
  data() {
    return {
      showConfirmationModal: false,
      title: '',
      label: '',
      btnLabel: '',
      isAcceptedClick: false,
      showSuccessNotification: false,
      showSpinnnerInAcceptButton: false,
      showSpinnnerInRejectButton: false,
      showSpinnnerInCancelButton: false,
      showCancelModal: false,
      cancellationFeeInPercentage: 0,
      cancelationMessage: '',
    };
  },
  computed: {
    canBeRejected(): boolean {
      if (this.order.status != 'Pending') {
        return false;
      }
      const startDate = parseISO(this.order.start);
      return isBefore(new Date(), startDate);
    },

    canBeChanged(): boolean {
      return this.order.sourceType == 'None';
    },

    canBeAccepted(): boolean {
      if (this.order.status != 'Pending') {
        return false;
      }
      const startDate = parseISO(this.order.start);
      return isBefore(new Date(), startDate);
    },

    canBeCanceled(): boolean {
      if (this.order.status != 'Confirmed') {
        return false;
      }
      return true;
    },

    getNotificationMessage(): string {
      if (this.isAcceptedClick) {
        return this.$t('components.HostOrderDetailButtons.successNotification').toString();
      } else {
        return this.$t('components.HostOrderDetailButtons.rejectNotification').toString();
      }
    },
  },
  methods: {
    handleReject() {
      this.title = this.$t('components.hostOrderDetailButtons.titleReject').toString();
      this.label = this.$t('components.hostOrderDetailButtons.label', {
        guestName: this.order.customerName,
      }).toString();
      this.btnLabel = this.$t('components.hostOrderDetailButtons.reject').toString();
      this.isAcceptedClick = false;
      this.showConfirmationModal = true;
    },

    handleAccept() {
      this.title = this.$t('components.hostOrderDetailButtons.titleAccept').toString();
      this.label = this.$t('components.hostOrderDetailButtons.label', {
        guestName: this.order.customerName,
      }).toString();
      this.btnLabel = this.$t('components.hostOrderDetailButtons.accept').toString();
      this.isAcceptedClick = true;
      this.showConfirmationModal = true;
    },

    handleCancel() {
      this.showCancelModal = true;
    },

    closeConfirmationModal() {
      this.showCancelModal = false;
    },

    onCancel() {
      this.showCancelModal = false;
      this.showSpinnnerInCancelButton = true;
      OwnerOrderService.cancelOrder(this.order.orderId, this.cancellationFeeInPercentage, this.cancelationMessage).then(
        () => {
          this.showSpinnnerInCancelButton = false;
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('components.hostOrderDetailButtons.successMsg').toString(), 'bg-success')
          );
          this.showSuccessNotification = true;
        },
        (error) => {
          this.showSpinnnerInCancelButton = false;
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(
              this.$t('components.hostOrderDetailButtons.errorMsg').toString() + error.message,
              'bg-warning'
            )
          );
        }
      );
    },

    onUpdateShow(show: boolean, message: string) {
      this.showConfirmationModal = show;
      if (this.isAcceptedClick) {
        this.showSpinnnerInAcceptButton = true;
        OwnerOrderService.acceptOrder(this.order.orderId, message).then(
          () => {
            this.showSpinnnerInAcceptButton = false;
            this.$store.dispatch('NextActivitiesStoreModule/removeNewOrder', this.order.orderId);
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(this.$t('components.hostOrderDetailButtons.successMsg').toString(), 'bg-success')
            );
            this.showSuccessNotification = true;
          },
          (error) => {
            this.showSpinnnerInAcceptButton = false;
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(
                this.$t('components.hostOrderDetailButtons.errorMsg').toString() + error.message,
                'bg-warning'
              )
            );
          }
        );
      } else {
        this.showSpinnnerInRejectButton = true;
        OwnerOrderService.rejectOrder(this.order.orderId, message).then(
          () => {
            this.showSpinnnerInRejectButton = false;
            this.$store.dispatch('NextActivitiesStoreModule/removeNewOrder', this.order.orderId);
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(
                this.$t('components.hostOrderDetailButtons.rejectionSuccessMsg').toString(),
                'bg-success'
              )
            );
            this.showSuccessNotification = true;
          },
          (error) => {
            this.showSpinnnerInRejectButton = false;
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(
                this.$t('components.hostOrderDetailButtons.errorMsg').toString() + error.message,
                'bg-warning'
              )
            );
          }
        );
      }
    },

    onCloseModal() {
      this.showConfirmationModal = false;
    },

    handleCloseSuccessNotification() {
      this.$router.push({ name: 'OwnerOrderIndex' });
    },
  },
});
