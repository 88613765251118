


















































































import Vue, { PropType } from 'vue';
import { validationMixin } from 'vuelidate';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';
import { de } from 'date-fns/locale';
import toDate from 'date-fns-tz/toDate';
import format from 'date-fns-tz/format';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import { required } from 'vuelidate/lib/validators';

const validations = {
  selectedCheckInDate: { required },
  selectedCheckOutDate: { required },
  checkInDate: { required },
  checkOutDate: { required },
  checkInTime: { required },
  checkOutTime: { required },
  order: {
    start: {
      required,
    },
    end: {
      required,
    },
  },
};

export default Vue.extend({
  components: { ValidatedSelect },
  mixins: [validationMixin],
  validations,
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
    },
  },
  data() {
    return {
      selectedCheckInDate: new Date(this.order.start) as Date | string,
      selectedCheckOutDate: new Date(this.order.end) as Date | string,
      checkInDate: '',
      checkOutDate: '',
      checkInTime: '',
      checkOutTime: '',
      dataInit: true,
    };
  },
  watch: {
    order: {
      immediate: true,
      handler() {
        this.dataInit = true;
        this.checkInDate = this.dateToString(this.selectedCheckInDate);
        this.checkOutDate = this.dateToString(this.selectedCheckOutDate);
        this.checkInTime = this.formatTime(this.order.start);
        this.checkOutTime = this.formatTime(this.order.end);
      },
    },
    selectedCheckInDate: {
      handler() {
        if (this.selectedCheckInDate) {
          this.checkInDate = this.dateToString(this.selectedCheckInDate);
          this.setCheckInInOrder();
        } else {
          this.checkInDate = '';
          this.order.start = '';
        }
        this.checkInTime = '00:00';
        this.selectedCheckOutDate = '';
        this.checkOutDate = '';
        this.checkOutTime = '00:30';
        this.setAnyError();
      },
    },
    checkInTime: {
      immediate: true,
      handler() {
        this.setCheckInInOrder();
      },
    },
    selectedCheckOutDate: {
      handler() {
        this.checkOutDate = this.dateToString(this.selectedCheckOutDate);
        this.setCheckInOutOrder();
        this.order.isDirty = true;
        this.setAnyError();
      },
    },
    checkOutTime: {
      immediate: true,
      handler() {
        this.setCheckInOutOrder();
      },
    },
    isDirty: {
      immediate: true,
      handler() {
        this.setDirty();
      },
    },
  },
  computed: {
    isDirty(): boolean {
      return this.$v.$anyDirty;
    },
    checkOutTimes(): { value: string; label: string }[] {
      let slots: { value: string; label: string }[] = [];
      for (let index = 0; index < 24; index++) {
        slots.push({ value: `${('0' + index).slice(-2)}:00`, label: `${('0' + index).slice(-2)}:00` });
        slots.push({ value: `${('0' + index).slice(-2)}:30`, label: `${('0' + index).slice(-2)}:30` });
      }
      return slots;
    },

    checkInTimes(): { value: string; label: string }[] {
      let slots: { value: string; label: string }[] = [];
      for (let index = 0; index < 24; index++) {
        slots.push({ value: `${('0' + index).slice(-2)}:00`, label: `${('0' + index).slice(-2)}:00` });
        slots.push({ value: `${('0' + index).slice(-2)}:30`, label: `${('0' + index).slice(-2)}:30` });
      }
      return slots;
    },
    nextDate(): Date | null {
      if (this.selectedCheckInDate) {
        const tomorrow = new Date(this.selectedCheckInDate);
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow;
      }
      return new Date();
    },
  },

  methods: {
    setDirty() {
      if (this.$v.$anyDirty) {
        this.order.isDirty = true;
      }
    },
    setAnyError() {
      this.order.anyError = !(this.selectedCheckInDate && this.selectedCheckOutDate);
    },
    dateToString(dt: Date | string): string {
      return dt && dt instanceof Date ? format(dt, 'yyyy-MM-dd', { locale: de, timeZone: 'Europe/Berlin' }) : '';
    },
    formatTime(dateString: string): string {
      let date = toDate(dateString, { timeZone: 'Europe/Berlin' });
      date = utcToZonedTime(date, 'Europe/Berlin');
      return format(date, 'HH:mm', { locale: de, timeZone: 'Europe/Berlin' });
    },
    setCheckInInOrder() {
      if (this.checkInDate && this.checkInTime) {
        let dateString = this.checkInDate + ' ' + this.checkInTime;
        let date = toDate(dateString, { timeZone: 'Europe/Berlin' });
        date = utcToZonedTime(date, 'Europe/Berlin');
        this.order.start = format(date, 'Pp', { locale: de, timeZone: 'Europe/Berlin' });
      }
    },
    setCheckInOutOrder() {
      if (this.checkOutDate && this.checkOutTime) {
        let dateString = this.checkOutDate + ' ' + this.checkOutTime;
        let date = toDate(dateString, { timeZone: 'Europe/Berlin' });
        date = utcToZonedTime(date, 'Europe/Berlin');
        this.order.end = format(date, 'Pp', { locale: de, timeZone: 'Europe/Berlin' });
      }
    },
  },
});
