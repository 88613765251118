


























import Vue from 'vue';
import { maxLength, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ValidatedTextArea from '@/components/forms/ValidatedTextArea.vue';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
const validations = {
  message: {
    required,
    maxLength: maxLength(500),
  },
  acceptBusinessTerms: {
    required,
  },
};

export default Vue.extend({
  components: { ValidatedTextArea, ValidatedCheckbox },
  validations,
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    btnLabel: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    saving: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      ready: false,
      message: '',
      acceptBusinessTerms: false,
    };
  },
  mounted() {
    this.message = '';
    this.acceptBusinessTerms = false;
  },
  computed: {
    canSaveChange(): boolean {
      return this.acceptBusinessTerms;
    },
  },
  methods: {
    onUpdateShow() {
      this.acceptBusinessTerms = false;
      this.$emit('onUpdateShow', false, this.message);
    },
    onClose() {
      this.$emit('onClose');
    },
  },
});
