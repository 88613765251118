









import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';
import Vue, { PropType } from 'vue';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import { validationMixin } from 'vuelidate';

const validations = {
  order: {
    bookingType: {},
  },
};
export default Vue.extend({
  components: { ValidatedSelect },
  mixins: [validationMixin],
  validations,
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
      required: true,
    },
  },
  watch: {
    isDirty: {
      immediate: true,
      handler() {
        this.setDirty();
      },
    },
    anyError: {
      immediate: true,
      handler() {
        this.setAnyError();
      },
    },
  },
  computed: {
    bookingTypes(): string[] {
      if (this.order.playroom == undefined) {
        return [];
      }
      const validBookingTypes = [];
      if (this.order.playroom.allowHourBooking) {
        validBookingTypes.push('hourly');
      }
      if (this.order.playroom.allowOvernightStay) {
        validBookingTypes.push('overnight');
      }
      return validBookingTypes;
    },
    isDirty(): boolean {
      return this.$v.$anyDirty;
    },
    anyError(): boolean {
      return this.$v.$anyError;
    },
    bookingTypeOptions(): { value: string; label: string }[] {
      return this.bookingTypes.map((t) => ({
        value: t,
        label: this.$t('enums.bookingType.' + t).toString(),
      }));
    },

    getBookingTypeLabel(): string {
      return this.$t('forms.playroomSearch.bookingType.label').toString();
    },
  },
  methods: {
    setDirty() {
      if (this.$v.$anyDirty) {
        this.order.isDirty = true;
      }
    },
    setAnyError() {
      this.order.anyError = this.$v.$anyError;
    },
  },
});
