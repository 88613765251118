
































import Vue, { PropType, VueConstructor } from 'vue';
import CustomerOrderDetailDto, { OrderEvents } from '@/models/CustomerOrderDetailDto';
import FormaterMixin from '@/mixins/FormaterMixin.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  mixins: [FormaterMixin],
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
    },
  },

  methods: {
    formatFullDate(dateString: string): string {
      return this.formatIsoDateTimeTz(dateString, 'P | p');
    },
    formatDistance(dateString: string): string {
      return this.formatIsoDateTimeDistanceTz(dateString);
    },
  },

  computed: {
    events(): OrderEvents[] {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.order.orderEvents.sort((a, b) => (a.date > b.date ? -1 : a.date < b.date ? 1 : 0));
    },
  },
});
